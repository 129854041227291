<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-overlay
          :show="load"
          variant="transparent"
        >
          <template #overlay>
            <div class="text-center">
              <feather-icon
                icon="ClockIcon"
                size="40"
                animation="cyclon"
              />
              <p>Aguarde...</p>
            </div>
          </template>
          <b-card>
            <b-row>
              <b-col>
                <b-col
                  cols="12"
                >
                  <b-row align-h="between">
                    <b-col
                      md="1"
                      class="my-1"
                    >
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="[10, 15, 20]"
                      />
                    </b-col>
                    <b-col
                      md="8"
                      class="my-1"
                    >
                      <b-form-input
                        v-model="filter"
                        placeholder="Pesquisar"
                        type="search"
                        size="sm"
                      />
                    </b-col>
                  </b-row>
                  <b-row
                    class="mb-2"
                  >
                    <b-col
                      md="2"
                    >
                      <label>Filtro por Status</label>
                      <v-select
                        v-model="statusFilter"
                        :options="optionsStatus"
                        multiple
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-col>
              <b-col md="12">
                <b-Table
                  hover
                  small
                  striped
                  responsive
                  :per-page="perPage"
                  :current-page="currentPage"
                  :filter="filter"
                  :items="newListFiltered"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="mb-0"
                  @filtered="onFiltered"
                  @row-clicked="rowClick"
                >
                  <template #cell(status)="data">
                    <b-badge :variant="data.item.status === 'ATIVA' ? 'success' : 'danger'">
                      {{ data.item.status }}
                    </b-badge>
                  </template>
                </b-Table>
              </b-col>
              <b-col
                cols="12"
                class="mt-1"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="center"
                  size="sm"
                  class="my-0"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-card
      v-if="showListClients"
      :title="`Clientes da campanha ${campanhaID}`"
    >
      <b-row
        class="mb-2 ml-1"
      >
        <b-button
          v-show="clientesCampanha.length > 0"
          variant="outline-warning"
          @click="exportFile"
        >
          Exportar excel
        </b-button>
        <b-col
          md="8"
        >
          <b-form-input
            v-model="filter1"
            placeholder="Pesquisar"
            type="search"
            size="sm"
          />
        </b-col>
      </b-row>
      <b-table
        small
        striped
        responsive
        sticky-header="40vh"
        :filter="filter1"
        :fields="fieldsClientes"
        :per-page="25"
        :current-page="currentPageClients"
        :items="clientesCampanha"
        @filtered="onFilteredClients"
      >
        <template #cell(goTo)="data">
          <feather-icon
            icon="NavigationIcon"
            class="color-icon mr-1 cursor-pointer"
            @click="setCliente(data.item)"
          />
        </template>
      </b-table>
      <b-pagination
        v-model="currentPageClients"
        :total-rows="totalRowsClients"
        :per-page="25"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BPagination,
  BFormInput,
  BFormSelect,
  BOverlay,
  BButton,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { saveAs } from 'file-saver'
import axios from '@/../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BPagination,
    BFormInput,
    BFormSelect,
    BOverlay,
    BButton,
    BBadge,
    vSelect,
  },
  data() {
    return {
      load: true,
      filter: '',
      perPage: 10,
      currentPage: 1,
      currentPageClients: 1,
      totalRows: 1,
      totalRowsClients: 1,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      fields: [
        { key: 'id', label: 'ID', class: 'text-center' },
        { key: 'data_inicio_campanha', label: 'Data Início Campanha', class: 'text-center' },
        { key: 'data_final_campanha', label: 'Data Final Campanha', class: 'text-center' },
        { key: 'status', label: 'status', class: 'text-center' },
      ],
      campanhas: [],
      clientesCampanha: [],
      showListClients: false,
      fieldsClientes: [
        { key: 'id', label: 'CÓDIGO', class: 'text-center' },
        { key: 'nome', label: 'CLIENTE', class: 'text-center' },
        { key: 'goTo', label: 'Ir para cliente', class: 'text-center' },
      ],
      filter1: '',
      campanhaID: null,
      statusFilter: [],
    }
  },
  computed: {
    optionsStatus() {
      const { campanhas } = this
      const status = []

      campanhas.map(item => {
        item.status ? status.push(item.status) : null
      })

      const itemsFiltered = [...new Set(status)]

      return itemsFiltered
    },
    newListFiltered() {
      const {
        campanhas, statusFilter: sf,
      } = this
      let list = campanhas

      if (sf.length > 0) {
        list = []

        // Filtro por status
        let newFilterStatus = []
        const filterListStatus = []
        list.length > 0 ? (newFilterStatus = list) : (newFilterStatus = campanhas)
        for (let i = 0; i <= sf.length - 1; i++) {
          newFilterStatus.map(item => {
            if (item.status === sf[i]) {
              filterListStatus.push(item)
            }
          })
          list = filterListStatus
        }
      }
      this.totalRows = list.length
      return list
    },
  },
  async created() {
    const executivoID = parseInt(localStorage.getItem('executivo_id'))
    if (executivoID) {
      await this.getCampanhaManual(executivoID)
    } else {
      this.$swal({
        title: 'ERRO',
        icon: 'error',
        text: 'Ops, essa funcionalidade é so para executivos de cobrança!',
        customClass: {
          confirmButtonVariant: 'btn btn-primary',
        },
      })
    }
  },
  methods: {
    rowClick(item) {
      this.clientesCampanha = []
      this.campanhaID = null

      this.showListClients = true
      this.campanhaID = item.id
      item.clientes.map(items => {
        this.clientesCampanha.push(items)
      })
      this.totalRowsClients = this.clientesCampanha.length
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onFilteredClients(filteredItems) {
      this.totalRowsClients = filteredItems.length
      this.currentPageClients = 1
    },
    getCampanhaManual(id) {
      this.campanhas = []
      this.load = true
      axios.get(`api/v1/campanha/clientes/manual/${id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          Accept: 'application/json',
        },
      })
        .then(res => {
          const campanhas = []
          res.data.dados.campanha.map(dt => {
            campanhas.push({
              id: dt.id,
              data_inicio_campanha: this.formatTimezone(dt.data_inicio_campanha),
              data_final_campanha: this.formatTimezone(dt.data_final_campanha),
              status: dt.status ? 'ATIVA' : 'INATIVA',
              clientes: dt.clientes,
            })
          })
          this.campanhas = campanhas
          this.totalRows = this.campanhas.length
          this.load = false
        })
        .catch(error => {
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: `Ops, algo deu errado: ${error.response.data.mensagem}`,
            customClass: {
              confirmButtonVariant: 'btn btn-primary',
            },
          })
        })
    },
    async setCliente(item) {
      this.$router.push({ name: 'negociacao-cliente', params: { id: item.id } })
    },
    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },

    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório de Clientes Campanha Manual')
      const itemsPlan = this.clientesCampanha

      worksheet.columns = [
        { header: 'CLIENTE ID', key: 'id' },
        { header: 'CLIENTE', key: 'nome' },
      ]

      itemsPlan.map(item => {
        worksheet.addRow([
          item.id,
          item.nome,
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório de clientes campanhas manual', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
  },
}
</script>
